import React, {  Fragment, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { DollarSign, Tag, ShoppingBag, Sun} from 'react-feather';
import CountUp from 'react-countup';
import ChartistGraph from 'react-chartist';
import { Chart } from "react-google-charts";
import configDB from '../../data/customizer/config';
import { Line } from 'react-chartjs-2';

var Knob = require('knob')
const primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

export var lineChartData = {
    labels: ['June 2nd', 'June 3rd', 'June 4th', 'June 5th', 'June 6th', 'June 7th', 'June 8th'],
    datasets: [
        {
            label: 'No of samples tested',
            data: [50, 59, 80, 81, 56, 55, 40],
            backgroundColor: 'rgba(68, 102, 242, 0.3)',
            borderColor: "#4466f2",
            borderWidth: 2,
        }
    ],
    plugins: {
        datalabels: {
            display: false,
            color: 'white'
        }
    }
}

export var lineChartOptions = {
    maintainAspectRatio: true,
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            stacked: true,
        }],
        yAxes: [{
            stacked: true,
        }]
    },
    plugins: {
        datalabels: {
            display: false,
        }
    }
}

const MyDashBoard = () => {
    const datasetKeyProvider = () =>{
        return Math.random();
    }
     useEffect(() => {
        var profit = Knob({
            value: 35,
            left: 1,
            angleOffset: 90,
            className: "review",
            thickness: 0.2,
            width: 270,
            height: 270,
            fgColor: primary,
            readOnly: false,
            dynamicDraw: false,
            tickColorizeValues: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious:true
        })
        document.getElementById('profit').appendChild(profit);
    },[]);

    useEffect(() => {
        var profit2 = Knob({
            value: 25,
            left: 1,
            angleOffset: 90,
            className: "review",
            thickness: 0.2,
            width: 270,
            height: 270,
            fgColor: primary,
            readOnly: false,
            dynamicDraw: false,
            tickColorizeValues: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious:true
        })
        document.getElementById('profit2').appendChild(profit2);
    },[]);
        return (
            <Fragment>
             <Breadcrumb   parent = "Dashboard" title = "Default" />
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                         <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <h5>Completed</h5>
                          </div>
                          <div className="col-md-6 col-sm-12 text-right">
                            <button className="btn btn-primary"> Report to ICMR </button>
                          </div>
                         </div>
                        </div>
                        <div className="card-body chart-block">
                            <Line data={lineChartData} options={lineChartOptions}  datasetKeyProvider={datasetKeyProvider} width={550} height={250} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-md-6 xl-50">
                        <div className="card">
                            <div className="card-header">
                                <h5>Results Shared</h5>
                            </div>
                            <div className="card-body">
                                <div className="knob-block text-center">
                                    <div className="knob" id="profit">
                                    </div>
                                </div>
                                <div className="show-value d-flex">
                                    <div className="value-left">
                                        <div className="square bg-primary d-inline-block"></div><span>Total Results</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 xl-50">
                        <div className="card">
                            <div className="card-header">
                                <h5>Handed over to lab</h5>
                            </div>
                            <div className="card-body">
                                <div className="knob-block text-center">
                                    <div className="knob" id="profit2">
                                    </div>
                                </div>
                                <div className="show-value d-flex">
                                    <div className="value-left">
                                        <div className="square bg-primary d-inline-block"></div><span>Total Samples</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 xl-50">
                        <div className="card">
                            <div className="card-header">
                                <h5>Samples Collected</h5>
                            </div>
                            <div className="card-body">
                                <Chart
                                    width={'100%'}
                                    height={'400px'}
                                    chartType="BarChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ['Agent', 'Samples', ],
                                        ['Agent 6', 20],
                                        ['Agent 7', 15],
                                        ['Agent 8', 18],
                                        ['Agent 9', 12],
                                        ['Agent 10', 5],
                                    ]}
                                    options={{
                                        chartArea: { width: '50%' },
                                        colors: ["#4466f2"],
                                        hAxis: {
                                            title: 'Total Samples Collected',
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: 'Agent',
                                        },
                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6 xl-50">
                        <div className="card">
                            <div className="card-header">
                                <h5>Due for Collection</h5>
                            </div>
                            <div className="card-body">
                                <Chart
                                    width={'100%'}
                                    height={'400px'}
                                    chartType="BarChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ['Agent', 'Samples', ],
                                        ['Agent 1', 10],
                                        ['Agent 2', 15],
                                        ['Agent 3', 8],
                                        ['Agent 4', 16],
                                        ['Agent 5', 5],
                                    ]}
                                    options={{
                                        chartArea: { width: '50%' },
                                        colors: ["#1ea6ec"],
                                        hAxis: {
                                            title: 'Total Samples Due',
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: 'Agent',
                                        },
                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '1' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

export default MyDashBoard;