import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Icon, IconButton, Slide, TextField, FormHelperText, MenuItem, TablePagination, Accordion,AccordionSummary, AccordionDetails,Typography,Snackbar, Tooltip  } from '@material-ui/core';
import { downloadcsv, postvendormap, getclassifications, getsubclassifications, getAllUser, gettargetVendors, posttargetVendors, updatetargetVendors, deletetargetVendors, getvendormappings} from '../../actions/asyncActions';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, MoreHorizontal  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { CSVLink } from "react-csv";
import {Close as CloseIcon } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Download } from 'react-feather';
import Brouchere from './Brouchere.js';
import Loc from './Loc.js';
import Nda from './Nda.js';
import Dossier from './Dossier.js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


const elementStyle ={
    border:'solid',
    borderRadius:'10px',
    position:'relative',
    left:'1vh',
    height:'6vh',
    width:'23vh',
    marginTop:'1vh',
    marginBottom:'1vh',
    textAlign: 'center'
  }

  

const TargetVendors = () => {
     const classes = useStyles();
     const user = JSON.parse(localStorage.getItem("m_users"));

    const [targetvendors, setTargetVendors] = useState([]);
    const [filteredstock, setFilteredStock] = useState([]);

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredvendor, setFilteredVendor] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [values, setValues] = useState({}); 
    const [clasvalues, setClasValues] = useState({});
    const initialFormState = { id: '', vendor_name:'', country:'', prefered_vendor:'', is_approved:'', personincharge:'', vendormanagedentity:'', status_ofcontact:'', remarks:''} 
    const [formtargetvendor, setFormtargetvendor ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();
    const [uploadmodal, setUploadModal] = useState();
    const [vendorid, setVendorid] = useState();
    const [classificationmodal, setClassficationModal] = useState();
    const [vendormap, setVendormap] = useState([]);
    const [vendormapid, setVendormapid] = useState();
    const [classifications, setClassifications] = useState([]);
    const [subclassifications, setSubClassifications] = useState([]);
    const [viewModal, setViewModal] = useState();
    const [addclassificationmodal, setAddClassificationModal] = useState();
    const [open_download, setOpenDownload] = React.useState(false);
    const [download_bank, setDownload] = useState([]);

    useEffect( () => {
    gettargetVendors()
      .then(resp => setTargetVendors(resp.data))
    }, []);

    useEffect(() => {
     getAllUser()
     .then(resp => {
      setUsers(resp.data);
     })
    }, []);

    useEffect(() => {
      getvendormappings()
      .then(resp => {
        setVendormap(resp.data);
      })
    }, []);

    const handleReviewClose = () => {
    setOpenDownload(false);
  };


    useEffect( () => {
    getsubclassifications()
      .then(resp => setSubClassifications(resp.data))
    }, []);

    useEffect( () => {
    getclassifications()
    .then(resp => setClassifications(resp.data))
    }, []);

    useEffect( () => {
    downloadcsv()
    .then(resp => setDownload(resp.data))
  }, []);


    const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

  useEffect(() => {
    setFilteredVendor(
    targetvendors.filter(n => {
      return (n.vendor_name.toLowerCase().includes(search.toLowerCase()) || n.country.toLowerCase().includes(search.toLowerCase()) || n.personincharge.toLowerCase().includes(search.toLowerCase()) || n.vendormanagedentity.toLowerCase().includes(search.toLowerCase()) || n.status_ofcontact.toLowerCase().includes(search.toLowerCase()));
     })
    )
  }, [search, targetvendors])

    

    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleClassChange = event => {
        setClasValues({
          ...clasvalues,
          [event.target.name]: event.target.value
        });
    };

    const handleClassficationSubmit = () => {
       const Classi = {
          vendor_id: vendormapid,
          classification_id: clasvalues.class_name,
          sub_classification_id: clasvalues.sub_class_name,

        }
        postvendormap(Classi)
        .then(resp => {
             getvendormappings()
        .then(resp => 
        setVendormap(resp.data))   
          })
          .catch(err => {
            console.log("Error", err);
        })
          setAddClassificationModal(!addclassificationmodal)
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setFormtargetvendor({ ...formtargetvendor, [name]: value })
    }

    const uploadmodalopen = (n) => {
      setUploadModal(!uploadmodal)
      setVendorid(n.id);
    }

    const classficationmodal = (n) => {
      setClassficationModal(!classificationmodal)
      setVendormapid(n.id);

    }

    const addRow = () => {
     setAddModal(!addmodal)
    }
    const addClassificationRow = () => {
     setAddClassificationModal(!addclassificationmodal)
    }

    const viewRow = (vendor) => {
     setViewModal(!viewModal)
     setFormtargetvendor({ id:vendor.id , vendor_name:vendor.vendor_name, country:vendor.country, prefered_vendor:vendor.prefered_vendor, is_approved:vendor.is_approved, personincharge:vendor.personincharge, vendormanagedentity:vendor.vendormanagedentity, status_ofcontact:vendor.status_ofcontact, remarks: vendor.remarks, broucherepath:vendor.broucherepath, dossierpath:vendor.dossierpath, locpath:vendor.locpath, ndapath: vendor.ndapath})
    }

   const editRow = vendor => {
     setModal(!modal)
     //setFormtargetvendor({ id:vendor.id , vendor_name:vendor.vendor_name, country:vendor.country, prefered_vendor:vendor.prefered_vendor, is_approved:vendor.is_approved, personincharge:vendor.personincharge, vendormanagedentity:vendor.vendormanagedentity, status_ofcontact:vendor.status_ofcontact, remarks: vendor.remarks})
    }


    const handleSubmit = () => {
       const addvendor = {
          vendor_name: values.vendor_name, 
          country:values.country, 
          prefered_vendor:values.prefered_vendor, 
          is_approved:values.is_approved, 
          personincharge:values.personincharge, 
          vendormanagedentity:values.vendormanagedentity, 
          status_ofcontact:values.status_ofcontact, 
          remarks:values.remarks
        }
        posttargetVendors(addvendor)
        .then(resp => {
             gettargetVendors()
        .then(resp => 
        setTargetVendors(resp.data))   
          })
          .catch(err => {
            console.log("Error", err);
        })
          setAddModal(!addmodal)
    }

     const handleUpdate = (data) => {
        const editvendor = {
          id: data.id,
          vendor_name:data.vendor_name, 
          country:data.country, 
          prefered_vendor:data.prefered_vendor, 
          is_approved:data.is_approved, 
          personincharge:data.personincharge, 
          vendormanagedentity:data.vendormanagedentity, 
          status_ofcontact:data.status_ofcontact, 
          remarks:data.remarks  
        }

        updatetargetVendors(editvendor)
        .then(resp => {
             gettargetVendors()
        .then(resp => 
        setTargetVendors(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }



     const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


   

  

  const statuslist = [
  {
    value: 'Not Connected',
    label: 'Not Connected',
  },
  {
    value: 'Connected Awaiting Details',
    label: 'Connected Awaiting Details',
  },
  {
    value: 'NDA Signed Awaiting Details',
    label: 'NDA Signed Awaiting Details',
  },
  {
    value: 'Under Negotiations',
    label: 'Under Negotiations',
  },
  {
    value: 'LOR /Agency AgreementSigned',
    label: 'LOR /Agency Agreement Signed',
  }
];


const vendor_managedentity = [
  {
    value: 'QBH',
    label: 'QBH',
  },
  {
    value: 'EUS',
    label: 'EUS',
  },
  {
    value: 'BIEWU',
    label: 'BIEWU',
  },
];

const handleDownloadSubmit = () => {
      gettargetVendors()
      .then(resp => {
        setTargetVendors(resp.data);
        console.log('Download File', resp.data);
      })
      .catch(err => {
        console.log("Error", err);
      })
      setOpenDownload(true);
  }


    const handleDeleteItem = (n) => {
      const data ={
        id: n.id
      }
      deletetargetVendors(data)
      .then(resp => {
        gettargetVendors()
        .then(resp => {
        setTargetVendors(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

    // const stockdataset = filteredstock.reduce((unique, s) => (unique.includes(s.stock_filename) ? unique : [...unique, s.stock_filename]),[],);


        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                 <input type="text" placeholder="Search Items" style={elementStyle} value={search}
                                       onChange={e => setSearch(e.target.value)} />
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Country</th>
                                                    <th scope="col">V M Entity</th>
                                                    <th scope="col">Person In charge</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredvendor.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                   
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{n.vendor_name}</td>
                                                     <td>{n.country}</td> 
                                                     <td>{n.vendormanagedentity}</td>
                                                      <td>{n.personincharge}</td>
                                                     <td>{n.status_ofcontact}</td>
                                                      
                                                   
                                                     <td>
                                                        <Tooltip title="View Vendor" placement="left">
                                                      <i className="fa fa-eye"  onClick={() => viewRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: 'pointer' }}
                                                      ></i></Tooltip>
                                                       <Tooltip title="Classfications" placement="top-start">
                                                     <i className="fa fa-code-fork" onClick={() => classficationmodal(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: 'pointer' }}
                                                      ></i></Tooltip>
                                                      <Tooltip title="Upload Docs" placement="top">
                                                     <i className="fa fa-cloud-upload" onClick={() => uploadmodalopen(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: 'pointer' }}
                                                      ></i></Tooltip>
                                                      <Tooltip title="Edit Vendor" placement="top-end">
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: 'pointer' }}
                                                      ></i></Tooltip>
                                                      <Tooltip title="Delete Vendor" placement="right">
                                                      <i className="fa fa-trash" onClick={() => handleDeleteItem(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: 'pointer' }}
                                                      ></i></Tooltip>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                       <button className="btn btn-pill btn-primary btn-sm" type="button" onClick={() => addRow()}>
                                   Add Vendor
                                  </button>&nbsp;
                                  <button className="btn btn-pill btn-secondary btn-sm" type="button" onClick={handleDownloadSubmit}>
                                     <Download size="14"/>Export
                                    </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={filteredvendor.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             

            {/* Add Modal */}

               <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add Vendor</ModalHeader>   
                <ModalBody> 
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Vendor Name:</label>
                              <input className="form-control" name="vendor_name" type="text" placeholder="Vendor Name" 
                               value={values.vendor_name} onChange={handleChange}/>
                            </div>
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Country:</label>
                              <input className="form-control" name="country" type="text" placeholder="Country" 
                               value={values.country} onChange={handleChange}/>
                            </div>
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Prefered Vendor:</label>
                              <input className="form-control" name="prefered_vendor" type="text" placeholder="Prefered Vendor" 
                               value={values.prefered_vendor} onChange={handleChange}/>
                            </div> 
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Is Approved:</label>
                              <input className="form-control" name="is_approved" type="text" placeholder="Is Approved" 
                               value={values.is_approved} onChange={handleChange}/>
                            </div>
                             
                             <div className="col-md-6 mb-3">
                             
                                      <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select Vendor Managed Entity"
                                      name="vendormanagedentity"
                                      value={values.vendormanagedentity}
                                      onChange={handleChange}
                                      helperText="Please Select Vendor Managed Entity"
                                    >
                                      {vendor_managedentity.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                            </div>
                             <div className="col-md-6 mb-3">
                              
                                      <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select Status"
                                      name="status_ofcontact"
                                      value={values.status_ofcontact}
                                      onChange={handleChange}
                                      helperText="Please select Status"
                                    >
                                      {statuslist.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                            </div>
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Remarks:</label>
                              <input className="form-control" name="remarks" type="text" placeholder="Remarks" 
                               value={values.remarks} onChange={handleChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                                      <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select Person In charge"
                                      name="personincharge"
                                      value={values.personincharge}
                                      onChange={handleChange}
                                      helperText="Please Select Person In charge"
                                    >
                                      {users.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Create</Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit Vendor</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Vendor Name:</label>
                              <input className="form-control" name="vendor_name" type="text" placeholder="Vendor Name" 
                               value={formtargetvendor.vendor_name} onChange={handleInputChange}/>
                            </div>
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Country:</label>
                              <input className="form-control" name="country" type="text" placeholder="Country" 
                               value={formtargetvendor.country} onChange={handleInputChange}/>
                            </div>
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Prefered Vendor:</label>
                              <input className="form-control" name="prefered_vendor" type="text" placeholder="Prefered Vendor" 
                               value={formtargetvendor.prefered_vendor} onChange={handleInputChange}/>
                            </div> 
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Is Approved:</label>
                              <input className="form-control" name="is_approved" type="text" placeholder="Is Approved" 
                               value={formtargetvendor.is_approved} onChange={handleInputChange}/>
                            </div>
                             
                             <div className="col-md-6 mb-3">
                                      <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select Vendor Managed Entity"
                                      name="vendormanagedentity"
                                      value={formtargetvendor.vendormanagedentity}
                                      onChange={handleChange}
                                      helperText="Please Select Vendor Managed Entity"
                                    >
                                      {vendor_managedentity.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                            </div>
                             <div className="col-md-6 mb-3">

                              
                                      <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select Status"
                                      name="status_of contact"
                                      value={formtargetvendor.status_ofcontact}
                                      onChange={handleInputChange}
                                      helperText="Please select Status"
                                    >
                                      {statuslist.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                            </div>
                             <div className="col-md-6 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Remarks:</label>
                              <input className="form-control" name="remarks" type="text" placeholder="Remarks" 
                               value={formtargetvendor.remarks} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-6 mb-3">
                                      <TextField
                                      id="standard-select-currency"
                                      select
                                      label="Select Person In charge"
                                      name="personincharge"
                                      value={formtargetvendor.personincharge}
                                      onChange={handleInputChange}
                                      helperText="Please Select Person In charge"
                                    >
                                      {users.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={() => handleUpdate(formtargetvendor)} >Update</Button>
                </ModalFooter>
            </Modal>



            <Modal isOpen={viewModal} toggle={viewRow}>
                <ModalHeader toggle={viewRow}>Vendor Details</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              
                               <TextField
                                  id="standard-read-only-input"
                                  label="VendorName"
                                  defaultValue={formtargetvendor.vendor_name}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">
                              

                                <TextField
                                  id="standard-read-only-input"
                                  label="Country"
                                  defaultValue={formtargetvendor.country}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">
                               <TextField
                                  id="standard-read-only-input"
                                  label="PreferedVendor"
                                  defaultValue={formtargetvendor.prefered_vendor}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div> 
                             <div className="col-md-6 mb-3">
                              
                               <TextField
                                  id="standard-read-only-input"
                                  label="IsApproved"
                                  defaultValue={formtargetvendor.is_approved}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             
                             <div className="col-md-6 mb-3">
                                     
                                     <TextField
                                  id="standard-read-only-input"
                                  label="VendorManagedEntity"
                                  defaultValue={formtargetvendor.vendormanagedentity}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">

                              <TextField
                                  id="standard-read-only-input"
                                  label="Status"
                                  defaultValue={formtargetvendor.status_ofcontact}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">
                                <TextField
                                  id="standard-read-only-input"
                                  label="Remarks"
                                  defaultValue={formtargetvendor.remarks}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                    <TextField
                                  id="standard-read-only-input"
                                  label="PersonIncharge"
                                  defaultValue={formtargetvendor.personincharge}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                                Doc Staus
                              </Typography>
                               <div>
                               Dossier :  {formtargetvendor.dossierpath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                               Doc Staus
                              </Typography>
                               <div>
                               Loc :  {formtargetvendor.locpath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                                Doc Staus
                              </Typography>
                               <div>
                               Brouchre : {formtargetvendor.broucherepath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                               Doc Staus
                              </Typography>
                               <div>
                               NDA :  {formtargetvendor.ndapath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={viewRow}>Cancel</Button>
                   
                </ModalFooter>
            </Modal>


            <Modal className="modal-dialog modal-xl" isOpen={uploadmodal} toggle={uploadmodalopen}>
                <ModalHeader toggle={uploadmodalopen}>Doc Management</ModalHeader>   
                <ModalBody>
                    <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Home />Brochure
                                </Tab>
                                <Tab>
                                    <Home />NDA
                                </Tab>
                                 <Tab>
                                    <Home />Dossier
                                </Tab>
                                <Tab>
                                    <Home />Agency Agreement
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <Brouchere vendorid = {vendorid}/>
                                </TabPanel>
                                <TabPanel>
                                     <Nda  vendorid = {vendorid}/>
                                </TabPanel>
                                <TabPanel>
                                     <Dossier vendorid = {vendorid}/>
                                </TabPanel>
                                <TabPanel>
                                     <Loc  vendorid = {vendorid}/>
                                </TabPanel>
                                
                            </div>
                        </Tabs>
                    </div>
                </div>
                </ModalBody>
               
            </Modal>


            <Modal isOpen={classificationmodal} toggle={classficationmodal}>
                <ModalHeader toggle={classficationmodal}>Vendor Classfications</ModalHeader>   
                <ModalBody>  
               {
                vendormap.filter(x => x.vendor_id == vendormapid).map((n,i)=> {
                  const sd = classifications.find(c => c.id == n.classification_id);
                  const sdc = subclassifications.filter(s => s.id == n.sub_classification_id);
                  return (
                    <Accordion>
                        <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>Classification: {sd && sd.classification_name}  </Typography>
                        </AccordionSummary>
                                <AccordionDetails>
                                  <Typography style={{ color: "green" }}> SubClassifications: 
                                   {sdc.map((s,i) => {
                                    return (
                                      <div>
                                   {s.sub_classfication_name}
                                   </div>
                                    )
                                   })}
                                  </Typography>
                                </AccordionDetails>
                         </Accordion>
                  );
                 })
               }
          
      </ModalBody>
        <ModalFooter>
                    <Button color="secondary" onClick={classficationmodal}>Cancel</Button>
                    <Button color="secondary" onClick={addClassificationRow}>Add Classfication</Button>
                    
                </ModalFooter>
                
            </Modal>


            <Modal isOpen={addclassificationmodal} toggle={addClassificationRow}>
                <ModalHeader toggle={addClassificationRow}>Add SubClassifications</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                             <TextField
                                  id="standard-select-currency"
                                  select
                                  label="Select Classification"
                                  name = "class_name"
                                  value={clasvalues.class_name}
                                  onChange={handleClassChange}
                                  helperText="Please select your Classification"
                                >
                                  {classifications.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.classification_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                            </div>
                            <div className="col-md-12 mb-3">
                              <TextField
                                  id="standard-select-currency"
                                  select
                                  label="Select SubClassification"
                                  name = "sub_class_name"
                                  value={clasvalues.sub_class_name}
                                  onChange={handleClassChange}
                                  helperText="Please select your SubClassification"
                                >
                                  {subclassifications.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.sub_classfication_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                            </div>
                            
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={addClassificationRow}>Cancel</Button>
                    <Button color="primary" onClick={handleClassficationSubmit} >Create</Button>
                </ModalFooter>
            </Modal>

            <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open_download}
          autoHideDuration={6000}
          onClose={handleReviewClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          color="primary"  
          TransitionComponent={Transition}      
          message={<span id="message-id">Do you want to Download, Click Yes</span>}
          action={[
            <CSVLink
              data={targetvendors}
              filename="vendors.csv"
              className="hidden"
              target="_blank">
              <Button onClick={gettargetVendors} size="sm" round color="success">Yes</Button>
           </CSVLink>,
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleReviewClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />



        </Fragment>
        );
}

export default TargetVendors;