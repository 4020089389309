
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { getAllUser,  sendEmaillink } from '../../actions/asyncActions';


const ForgotPassword = () => {

  const [values , setValues] = useState({
    email: '',
  });
  const [users, setUsers] = useState([]); 
  const [response, setResponse] = useState([]);
    const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };


    useEffect( () => {
        getAllUser()
        .then(resp => {
            setUsers(resp.data);
        })
       }, []);

    const handleSubmit = async () => {
    const validuser = users.find(x => x.email == values.email);
    const data = {
      email : values.email
    }
   
    if(validuser) {
        sendEmaillink(data)
        .then(resp => {
            setResponse(resp.data)
            alert("Check Your Inbox");
        })
        .catch(err => {
          console.log("error", err);
        })
    } 
    else {
        alert("Email Is Not Registred With Us...!")
    }
    }
    
   
    
    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                   
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Reset Your Password</h4>
                                                   
                                                </div>
                                                <form className="theme-form">
                                                     <div className="form-group">
                                                        
                                                        <input className="form-control" type="text" placeholder="Enter your Registered Email" name="email" value={values.email} onChange={handleChange} />
                                                    </div>
                                                    
                                                    <div className="card-footer text-right">
                                                        <button className="btn btn-primary" type="submit" onClick={handleSubmit}>Send Email</button>
                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default withRouter(ForgotPassword);



