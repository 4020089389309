import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, MenuItem, TablePagination } from '@material-ui/core';
import {  getclassifications, postclassifications, updateclassifications, deleteclassifications} from '../../actions/asyncActions';



const elementStyle = {
    border:'solid',
    borderRadius:'10px',
    position:'relative',
    left:'1vh',
    height:'6vh',
    width:'23vh',
    marginTop:'1vh',
    marginBottom:'1vh',
    textAlign: 'center'
  }

const Classifications = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const [classifications, setClassifications] = useState([]);
    const [filteredstock, setFilteredStock] = useState([]);
    const [search, setSearch] = useState('');
    const [stockdatamodal, setstockDataModal] = useState();
    const [stockDataFileTransactions, setstockDataFileTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [values, setValues] = useState({});
    const initialFormState = { id: '', classification_name:''}
    const [clasficationedit, setClasficationEdit ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();

    useEffect( () => {
    getclassifications()
      .then(resp => setClassifications(resp.data))
    }, []);



    const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

//   useEffect(() => {
//     setFilteredStock(
//     targetvendors.filter(n => {
//       return n.quarter.toLowerCase().includes(search.toLowerCase());
//      })
//     )
//   }, [search, targetvendors])

     const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    const handleViewTranscaction = (filename) => {
//    setstockDataModal(!stockdatamodal)
//    setstockDataFileTransactions(
//      filteredstock.filter(f => f.stock_filename == filename)
//     )
  }

  const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setClasficationEdit({ ...clasficationedit, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }



 const editRow = classification => {
     setModal(!modal)
     setClasficationEdit({ id: classification.id, classification_name: classification.classification_name })
    }

    const handleSubmit = () => {
       const addClassi = {
          classification_name: values.classfication_name,
          
        }
        postclassifications(addClassi)
        .then(resp => {
             getclassifications()
        .then(resp => 
        setClassifications(resp.data))   
          })
          .catch(err => {
            console.log("Error", err);
        })
          setAddModal(!addmodal)
    }

    const handleUpdate = (data) => {
        const editClassifi = {
          id: data.id,
          classification_name: data.classification_name,  
        }

        updateclassifications(editClassifi)
        .then(resp => {
             getclassifications()
        .then(resp => 
        setClassifications(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }



    const handleDeleteItem = (n) => {
      const data ={
        id: n.id
      }
      deleteclassifications(data)
      .then(resp => {
        getclassifications()
        .then(resp => {
    setClassifications(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

    // const stockdataset = filteredstock.reduce((unique, s) => (unique.includes(s.stock_filename) ? unique : [...unique, s.stock_filename]),[],);


        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                 <input type="text" placeholder="Search Items" style={elementStyle} value={search}
                                       onChange={e => setSearch(e.target.value)} />
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                    <th scope="col">ClassificationName</th>
                                                   
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {classifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                   
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{n.classification_name}</td>
                                                    
                                                     <td>
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => handleDeleteItem(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      <button className="btn btn-pill btn-primary btn-sm" type="button" onClick={() => addRow()}>
                                   Add Classification
                                  </button>&nbsp;
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={classifications.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             <Modal className="modal-dialog modal-xl" isOpen={stockdatamodal} toggle={handleViewTranscaction}>
                <ModalHeader toggle={handleViewTranscaction}>Transactions List</ModalHeader>
                <ModalBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                     <th scope="col">Manufacturer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  {classifications.map((n, i)=> {
                                                  return (
                                                    <tr className="table-sm">
                                            
                                                      <td>{n.id}</td>
                                                     
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                        </div>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={handleViewTranscaction}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* Add Modal  */}

               <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add Classifications</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Classification Name:</label>
                              <input className="form-control" name="classification_name" type="text" placeholder="Classification" 
                               value={values.classfication_name} onChange={handleChange}/>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Create</Button>
                </ModalFooter>
            </Modal>

               {/* Edit Modal  */}

               <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit Classifications</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Classification Name:</label>
                              <input className="form-control" name="classification_name" type="text" placeholder="Classification" 
                               value={clasficationedit.classification_name} onChange={handleInputChange}/>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={() => handleUpdate(clasficationedit)} >Update</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default Classifications;