import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import seven from '../../assets/images/user/7.jpg';
import { getUserdetails, getUser,  editUsers, postUsers } from '../../actions/asyncActions';

export default function UserEdit() {
//const UserEdit = () => {
  const user = JSON.parse(localStorage.getItem("m_users"));
  const user_id = user.id;
  console.log("user_id", user_id);

  const [user_list, setUsers] = useState([]);
  
  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: '',
    role: '',
    password: ''
    
  });
  // useEffect( () => {
  //   getUser(user_id)
  //   .then(resp => { setValues(resp.data) } )
  // }, []);

  useEffect( () => {
    getUserdetails(user_id)
    .then(resp => { setValues(resp.data) } )
  }, []);

  console.log("values", values);


  
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (event) => {
    const user = JSON.parse(localStorage.getItem("np_users"));
        const edit_user = {
          name: values.name,
          password: values.password,
          email: values.email,
          phone: values.phone,
        } 
        editUsers(edit_user)
        .then(resp => {
            getUser()
            .then(resp => setUsers(resp.data))
          })
          .catch(err => {
            console.log("Error", err);
          })
      }

    return (
        <Fragment>
            <Breadcrumb parent="User" title="Edit Profile" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">My Profile</h4>
                                    <div className="card-options">
                                        <a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="row mb-2">
                                            <div className="col-auto"><img className="img-70 rounded-circle" alt="" src={seven} /></div>
                                            <div className="col">
                                                <h3 className="mb-1">{user.name}</h3>
                                                <p className="mb-4">{user.role}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Email</label>
                                            <input className="form-control" type="text" placeholder="Email" name="email" value={values.email} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Password</label>
                                            <input className="form-control" type="password" value={values.password} readOnly />
                                        </div>
                                        <div className="form-footer">
                                            <button className="btn btn-primary btn-block" onClick={handleSubmit}>Save</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="card">
                                <div className="card-header">
                                    <h4 className="card-title mb-0">Edit Profile</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Name</label>
                                                <input className="form-control" type="text" placeholder="Name" name="name" value={values.name} onChange={handleChange} />
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Email</label>
                                                <input className="form-control" type="text" placeholder="UserName" name="email" value={values.email} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Password</label>
                                                <input className="form-control" type="password" placeholder="Password" name="password" value={values.password} onChange={handleChange} />
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Phone</label>
                                                <input className="form-control" type="text" placeholder="Phone" name="phone" value={values.phone} onChange={handleChange} />
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary" type="submit" onClick={handleSubmit} >Update Profile</button>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

//export default UserEdit;