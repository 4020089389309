import React, { Fragment, useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { DialogContentText, DialogTitle, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, FormHelperText, MenuItem, TablePagination, Divider } from '@material-ui/core';
import {  getclassifications,getsubclassifications, postsubclassifications, updatesubclassifications, deletesubclassifications, bulkSubClassificationUpload} from '../../actions/asyncActions';



const elementStyle ={
    border:'solid',
    borderRadius:'10px',
    position:'relative',
    left:'1vh',
    height:'6vh',
    width:'23vh',
    marginTop:'1vh',
    marginBottom:'1vh',
    textAlign: 'center'
  }

const SubClassifications = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));

    const [subclassifications, setSubClassifications] = useState([]);
    const [classifications, setClassifications] = useState([]);
    const [filteredstock, setFilteredStock] = useState([]);
    const [search, setSearch] = useState('');
    const [openupload , setOpenUpload] = useState(false);
    const [stockdatamodal, setstockDataModal] = useState();
    const [stockDataFileTransactions, setstockDataFileTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [bulk_upload, setUpload] = useState();
    const [uploadfile, setFile] = useState([]);

    const [values, setValues] = useState({});
    const initialFormState = { id: '', sub_classfication_name:'', description: '', classification_id: ''}
    const [classificationedit, setClassificationedit ] = useState(initialFormState)
    const [modal, setModal] = useState();
    const [addmodal, setAddModal] = useState();

    useEffect( () => {
    getsubclassifications()
      .then(resp => setSubClassifications(resp.data))
    }, []);

    useEffect( () => {
    getclassifications()
    .then(resp => setClassifications(resp.data))
    }, []);

    const bulkUpload = () => {
     setUpload(!bulk_upload)
    }

    const onFileDrop = (files) => {
      setFile(document.getElementById('file').files[0]);
    }


    const handleUploadSubmit = async (event) => {
     
      const formData = new FormData()
      formData.append( 'file', uploadfile )
      bulkSubClassificationUpload(formData)
      .then(resp => {
          getsubclassifications()
      .then(resp => setSubClassifications(resp.data))
          setUpload(!bulk_upload)
        })
        .catch(err => {
          console.log("Error", err);
      })
    }
  
    const updateSearch = (event) => {
    setSearch(event.target.value.substr(0,20));
  }

//   useEffect(() => {
//     setFilteredStock(
//     targetvendors.filter(n => {
//       return n.quarter.toLowerCase().includes(search.toLowerCase());
//      })
//     )
//   }, [search, targetvendors])

     const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    const handleViewTranscaction = (filename) => {
//    setstockDataModal(!stockdatamodal)
//    setstockDataFileTransactions(
//      filteredstock.filter(f => f.stock_filename == filename)
//     )
  }


   const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
    };

    const handleInputChange = event => {
        const { name, value } = event.target
        setClassificationedit({ ...classificationedit, [name]: value })
    }

    const addRow = () => {
     setAddModal(!addmodal)
    }



 const editRow = subclassification => {
     setModal(!modal)
     setClassificationedit({ id: subclassification.id, sub_classfication_name: subclassification.sub_classfication_name, description: '',   classification_id:subclassification.classfication_name })
    }

    const handleSubmit = () => {
       const addClassi = {
          sub_classfication_name: values.sub_classfication_name,
          description: values.description,
          classification_id: values.classfication_name,

        }
        postsubclassifications(addClassi)
        .then(resp => {
             getsubclassifications()
        .then(resp => 
        setSubClassifications(resp.data))   
          })
          .catch(err => {
            console.log("Error", err);
        })
          setAddModal(!addmodal)
    }

    const handleUpdate = (data) => {
        const editClassifi = {
          id: data.id,
          sub_classfication_name: data.sub_classfication_name,
          description: data.description,
          classification_id: data.classfication_name,
        }

        updatesubclassifications(editClassifi)
        .then(resp => {
             getsubclassifications()
        .then(resp => 
        setSubClassifications(resp.data))
            })
            .catch(err => {
              console.log("Error", err);
            })
        setModal(!modal)
    }



    const handleDeleteItem = (n) => {
      const data ={
        id: n.id
      }
      deletesubclassifications(data)
      .then(resp => {
        getsubclassifications()
        .then(resp => {
            setSubClassifications(resp.data);
        });
      })
      .catch(err => {
        console.log("Error", err);
      })
    };

    // const stockdataset = filteredstock.reduce((unique, s) => (unique.includes(s.stock_filename) ? unique : [...unique, s.stock_filename]),[],);


        return (
            <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                 <input type="text" placeholder="Search Items" style={elementStyle} value={search}
                                       onChange={e => setSearch(e.target.value)} />
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                    <th scope="col">Classification</th>
                                                     <th scope="col">SubClassification</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subclassifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                  const fullclassfication = classifications.find(x => x.id === n.classification_id);
                                                  
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{fullclassfication && fullclassfication.classification_name}</td>
                                                      <td>{n.sub_classfication_name}</td>
                                                     <td>
                                                      <i className="fa fa-edit" onClick={() => editRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                      <i className="fa fa-trash" onClick={() => handleDeleteItem(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                                                      ></i>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      <button className="btn btn-pill btn-success btn-sm" type="button" onClick={() => bulkUpload()} >
                                    Upload
                                  </button>&nbsp;
                                   <button className="btn btn-pill btn-primary btn-sm" type="button" onClick={() => addRow()}>
                                   Add SubClassification
                                  </button>
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={subclassifications.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Modal isOpen={bulk_upload} toggle={bulkUpload}>
                <ModalHeader toggle={bulkUpload}>Upload</ModalHeader>
                <ModalBody>
                  <div className="input-cropper col-md-9 mb-3">
                      <input id="file" type="file" onChange={onFileDrop} />
                  </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={bulkUpload}>Cancel</Button>
                    <Button color="primary" onClick={() => handleUploadSubmit()}>Save</Button>
                    
                </ModalFooter>
            </Modal>

             <Modal className="modal-dialog modal-xl" isOpen={stockdatamodal} toggle={handleViewTranscaction}>
                <ModalHeader toggle={handleViewTranscaction}>Transactions List</ModalHeader>
                <ModalBody>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                     <th scope="col">Manufacturer</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col">Part_number</th>
                                              
                                                    <th scope="col">Avg_ageing_days</th>
                                                     <th scope="col">Stock_age_class</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                  {subclassifications.map((n, i)=> {
                                                  return (
                                                    <tr className="table-sm">
                                            
                                                      <td>{n.id}</td>
                                                     
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                        </div>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={handleViewTranscaction}>Cancel</Button>
                </ModalFooter>
            </Modal>


            {/* Add Modal  */}

               <Modal isOpen={addmodal} toggle={addRow}>
                <ModalHeader toggle={addRow}>Add SubClassifications</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                             <TextField
                                  id="standard-select-currency"
                                  select
                                  label="Select Classification"
                                  name = "classfication_name"
                                  value={values.classfication_name}
                                  onChange={handleChange}
                                  helperText="Please select your Classification"
                                >
                                  {classifications.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.classification_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">SubClassification Name:</label>
                              <input className="form-control" name="sub_classfication_name" type="text" placeholder="SubClassification" 
                               value={values.sub_classfication_name} onChange={handleChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={values.description} onChange={handleChange}/>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={addRow}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} >Create</Button>
                </ModalFooter>
            </Modal>

               {/* Edit Modal  */}

               <Modal isOpen={modal} toggle={editRow}>
                <ModalHeader toggle={editRow}>Edit SubClassifications</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                         <div className="col-md-12 mb-3">
                            <TextField
                                  id="standard-select-currency"
                                  select
                                  label="Select Classification"
                                  name = "classfication_name"
                                  value={classificationedit.classfication_name}
                                  onChange={handleChange}
                                  helperText="Please select your Classification"
                                >
                                  {classifications.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.classification_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                            </div>
                            
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">SubClassification Name:</label>
                              <input className="form-control" name="sub_classfication_name" type="text" placeholder="SubClassification" 
                               value={classificationedit.sub_classfication_name} onChange={handleInputChange}/>
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="col-form-label" htmlFor="message-text">Description:</label>
                              <input className="form-control" name="description" type="text" placeholder="Description" 
                               value={classificationedit.description} onChange={handleInputChange}/>
                            </div>
                            
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={editRow}>Cancel</Button>
                    <Button color="primary" onClick={() => handleUpdate(classificationedit)} >Update</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
        );
}

export default SubClassifications;