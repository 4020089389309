// dashboard

export const NoOfVendors = "Tot Managed Vendors"
export const Connected = "QBH Partner Vendors"
export const NotConnected = "Dossier Not Available"
export const LorSigned = "BIEWU Partner Vendors"
export const NdaSigned = "Brochure Not Available"
export const UnderNegotiations = "Lor Not Available"






