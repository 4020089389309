import React, { useState, Fragment, useEffect } from 'react';
import ChartistGraph from 'react-chartist';
import { useHistory } from 'react-router-dom';
import { Users, Navigation, X, Check, Edit, File, Underline, Loader} from 'react-feather';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Table, TableBody,TableCell, TableContainer, TableHead, TableRow,  Paper, IconButton, Slide, TextField, FormHelperText, MenuItem, TablePagination, Accordion,AccordionSummary, AccordionDetails,Typography,Snackbar, Tooltip, Radio , RadioGroup , FormControlLabel , FormControl , FormLabel, Icon    } from '@material-ui/core';
import {NoOfVendors, Connected, NotConnected, LorSigned, NdaSigned, UnderNegotiations} from '../../constant'
import { gettargetVendors, getclassifications, getsubclassifications,getvendormappings} from '../../actions/asyncActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';




const elementStyle ={
    border:'solid',
    borderRadius:'10px',
    position:'relative',
    left:'1vh',
    height:'6vh',
    width:'23vh',
    marginTop:'1vh',
    marginBottom:'1vh',
    textAlign: 'center'
  }

  const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 650,
  },
}));



const Dashboard = props => {

 const classes = useStyles();
const [targetvendors, setTargetVendors] = useState([]);
const initialFormState = { id: '', vendor_name:'', country:'', prefered_vendor:'', is_approved:'', personincharge:'', vendormanagedentity:'', status_ofcontact:'', remarks:''} 
const [formtargetvendor, setFormtargetvendor ] = useState(initialFormState)
const [viewModal, setViewModal] = useState();
const [page, setPage] = useState(0);
const [search, setSearch] = useState('');
const [classificationmodal, setClassficationModal] = useState();
const [rowsPerPage, setRowsPerPage] = useState(5);
const [vendormapid, setVendormapid] = useState();
const [classifications, setClassifications] = useState([]);
const [subclassifications, setSubClassifications] = useState([]);
const [vendormap, setVendormap] = useState([]);
const totvendor = targetvendors.length; 
const connected =  targetvendors.filter(s => s.status_ofcontact == "Connected");
const unnegotiations = targetvendors.filter(s => s.status_ofcontact == "Under Negotiations");
const lor = targetvendors.filter(s => s.status_ofcontact == "LOR / MOU Signed");
const notconnected = targetvendors.filter(s => s.status_ofcontact == "Not Connected");
const ndsigned =  targetvendors.filter(s => s.status_ofcontact == "NDA signed; Awaiting Details");
const biewumanaged =  targetvendors.filter(s => s.vendormanagedentity == "EUS");
const eusmanaged = targetvendors.filter(s => s.vendormanagedentity == "BIEWU");
const brouchre = targetvendors.filter(s => s.broucherepath == "");
const dossier = targetvendors.filter(s => s.dossierpath == "");
const loc = targetvendors.filter(s => s.locpath == "");





useEffect( () => {
    gettargetVendors()
      .then(resp => setTargetVendors(resp.data))
    }, []);

useEffect(() => {
      getvendormappings()
      .then(resp => {
        setVendormap(resp.data);
      })
    }, []);



    useEffect( () => {
    getsubclassifications()
      .then(resp => setSubClassifications(resp.data))
    }, []);

    useEffect( () => {
    getclassifications()
    .then(resp => setClassifications(resp.data))
    }, []);

const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const statuslist = [
  {
    value: 'NotConnected',
    label: 'NotConnected',
  },
  {
    value: 'ConnectedAwaitingDetails',
    label: 'ConnectedAwaitingDetails',
  },
  {
    value: 'NDASignedAwaitingDetails',
    label: 'NDASignedAwaitingDetails',
  },
  {
    value: 'UnderNegotiations',
    label: 'UnderNegotiations',
  },
  {
    value: 'LOR/AgencyAgreementSigned',
    label: 'LOR/AgencyAgreementSigned',
  }
];


const vendor_managedentity = [
  {
    value: 'QBH',
    label: 'QBH',
  },
  {
    value: 'EUS',
    label: 'EUS',
  },
  {
    value: 'BIEWU',
    label: 'BIEWU',
  },
];

const viewRow = (vendor) => {
     setViewModal(!viewModal)
     setFormtargetvendor({ id:vendor.id , vendor_name:vendor.vendor_name, country:vendor.country, prefered_vendor:vendor.prefered_vendor, is_approved:vendor.is_approved, personincharge:vendor.personincharge, vendormanagedentity:vendor.vendormanagedentity, status_ofcontact:vendor.status_ofcontact, remarks: vendor.remarks, broucherepath:vendor.broucherepath, dossierpath:vendor.dossierpath, locpath:vendor.locpath, ndapath: vendor.ndapath })
    }

    const classficationmodal = (n) => {
      setClassficationModal(!classificationmodal)
      setVendormapid(n.id);

    }

     const vendorredirect = () => {
    window.location.href=`${process.env.PUBLIC_URL}/targetvendors`
     }

  //    const longText = () => {
  //       return (
  //           <div>
  //   <TableContainer component={Paper}>
  //     <Table className={classes.table} size="small" aria-label="a dense table">
  //       <TableHead>
  //         <TableRow>
  //           <TableCell>Dessert</TableCell>
  //           <TableCell align="right">Calories</TableCell>
            
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {biewumanaged.map((row) => (
  //           <TableRow key={row.vendor_name}>
  //             <TableCell component="th" scope="row">
  //               {row.name}
  //             </TableCell>
  //             <TableCell align="right">{row.vendor_name}</TableCell>
  //             <TableCell align="right">{row.country}</TableCell>
              
  //           </TableRow>
  //         ))}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  //   </div>
  // );

  //    }

    
    return (

        <Fragment>
           
            <div className="container-fluid">
                <div className="row"> 
                    <div className="col-xl-12 xl-100">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card"  style = {{ cursor: 'pointer' }} onClick={vendorredirect}>
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block">
                                                <Users />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h6>{NoOfVendors}</h6>
                                                <p>{totvendor}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                             
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block">
                                                <Loader />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h6>{Connected}</h6>
                                                <p>{biewumanaged.length}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block">
                                                <Loader />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h6>{LorSigned}</h6>
                                                <p>{eusmanaged.length}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-xl-12 xl-100">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block">
                                                <File />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h6>{NdaSigned}</h6>
                                                <p>{brouchre.length}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block">
                                                <File />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h6>{UnderNegotiations}</h6>
                                                <p>{loc.length}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="media feather-main">
                                            <div className="feather-icon-block">
                                                <File />
                                            </div>
                                            <div className="media-body align-self-center">
                                                <h6>{NotConnected}</h6>
                                                <p>{dossier.length}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-block row">
                                <div className="col-sm-12 col-lg-12 col-xl-12">
                                 {/*<input type="text" placeholder="Search Items" style={elementStyle} value={search}
                                       onChange={e => setSearch(e.target.value)} />*/}
                                    <div className="table-responsive-xl">
                                        <table className="table table-bordered table-hover">
                                            <thead className="table-primary">
                                                <tr className="text-center">
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Country</th>
                                                    <th scope="col">V M Entity</th>
                                                    <th scope="col">Person In charge</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {targetvendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                                                   
                                                  return (
                                                    <tr className="table-sm">
                                                     <td>{n.vendor_name}</td>
                                                     <td>{n.country}</td> 
                                                     <td>{n.vendormanagedentity}</td>
                                                      <td>{n.personincharge}</td>
                                                     <td>{n.status_ofcontact}</td>
                                                      
                                                   
                                                     <td>
                                                        <Tooltip title="View Vendor" placement="left">
                                                      <i className="fa fa-eye"  onClick={() => viewRow(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: 'pointer' }}
                                                      > </i></Tooltip>
                                                      <Tooltip title="Classfications" placement="top-start">
                                                     <i className="fa fa-code-fork" onClick={() => classficationmodal(n)} style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e', cursor: 'pointer' }}
                                                      ></i></Tooltip>
                                                     </td>
                                                    </tr>
                                                  );
                                                })
                                              }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="card">
                                    <div className="card-block row">
                                      <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                                      </div>
                                      <div className="col-sm-6 col-lg-6 col-xl-6">
                                        <TablePagination
                                          rowsPerPageOptions={[5, 10, 25]}
                                          component="div"
                                          count={targetvendors.length}
                                          rowsPerPage={rowsPerPage}
                                          page={page}
                                          onChangePage={handleChangePage}
                                          onChangeRowsPerPage={handleChangeRowsPerPage}
                                         />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


             <Modal isOpen={viewModal} toggle={viewRow}>
                <ModalHeader toggle={viewRow}>Vendor Details</ModalHeader>   
                <ModalBody>
                    <form>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                              
                               <TextField
                                  id="standard-read-only-input"
                                  label="VendorName"
                                  defaultValue={formtargetvendor.vendor_name}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">
                              

                                <TextField
                                  id="standard-read-only-input"
                                  label="Country"
                                  defaultValue={formtargetvendor.country}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">
                               <TextField
                                  id="standard-read-only-input"
                                  label="PreferedVendor"
                                  defaultValue={formtargetvendor.prefered_vendor}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div> 
                             <div className="col-md-6 mb-3">
                              
                               <TextField
                                  id="standard-read-only-input"
                                  label="IsApproved"
                                  defaultValue={formtargetvendor.is_approved}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             
                             <div className="col-md-6 mb-3">
                                     
                                     <TextField
                                  id="standard-read-only-input"
                                  label="VendorManagedEntity"
                                  defaultValue={formtargetvendor.vendormanagedentity}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">

                              <TextField
                                  id="standard-read-only-input"
                                  label="Status"
                                  defaultValue={formtargetvendor.status_ofcontact}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                             <div className="col-md-6 mb-3">
                                <TextField
                                  id="standard-read-only-input"
                                  label="Remarks"
                                  defaultValue={formtargetvendor.remarks}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                    <TextField
                                  id="standard-read-only-input"
                                  label="PersonIncharge"
                                  defaultValue={formtargetvendor.personincharge}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                                Doc Staus
                              </Typography>
                               <div>
                               Dossier :  {formtargetvendor.dossierpath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                               Doc Staus
                              </Typography>
                               <div>
                               Loc :  {formtargetvendor.locpath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                                Doc Staus
                              </Typography>
                               <div>
                               Brouchre : {formtargetvendor.broucherepath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                             <Typography variant="button" display="block" gutterBottom>
                               Doc Staus
                              </Typography>
                               <div>
                               NDA :  {formtargetvendor.ndapath == "" ? <Icon className="fa fa-times"  fontSize="small" style={{ color: green[500]}} />  :
                               <Icon className="fa fa-check"  fontSize="small" style={{ color: green[500]}} />}
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    
                    <Button color="secondary" onClick={viewRow}>Cancel</Button>
                   
                </ModalFooter>
            </Modal>

            <Modal isOpen={classificationmodal} toggle={classficationmodal}>
                <ModalHeader toggle={classficationmodal}>Vendor Classfications</ModalHeader>   
                <ModalBody>  
               {
                vendormap.filter(x => x.vendor_id == vendormapid).map((n,i)=> {
                  const sd = classifications.find(c => c.id == n.classification_id);
                  const sdc = subclassifications.filter(s => s.id == n.sub_classification_id);
                  return (
                    <Accordion>
                        <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>Classification: {sd && sd.classification_name}  </Typography>
                        </AccordionSummary>
                                <AccordionDetails>
                                  <Typography style={{ color: "green" }}> SubClassifications: 
                                   {sdc.map((s,i) => {
                                    return (
                                      <div>
                                   {s.sub_classfication_name}
                                   </div>
                                    )
                                   })}
                                  </Typography>
                                </AccordionDetails>
                         </Accordion>
                  );
                 })
               }
          
      </ModalBody>
        <ModalFooter>
                    <Button color="secondary" onClick={classficationmodal}>Cancel</Button>
                    
                    
                </ModalFooter>
                
            </Modal>
        </Fragment>
    );
}

export default Dashboard;