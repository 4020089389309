import React from "react";
import { signinUser2 } from './asyncActions';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    // case "CHECKOUT_SUCCESS":
    //   return { ...state, cart_items: action.data };
    // case "PLACE_ORDER":
    //   return { ...state, cart_items: [] };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

// const onKeyDown = (e) => {

//   }

//   const pressEnter = (e) => {
//     e.preventDefault();
//   }

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################


const alertImg = () => {
  //const onlineurl = "http://127.0.0.1:8000/dashapi";
const onlineurl = "http://biewu.nu-pie.com/dashapi";


  const user = JSON.parse(localStorage.getItem("m_users"));
   const userid = user.id;
   const userrole = user.role;
   const username =user.name;
  const data = JSON.stringify({"id":userid,"role":userrole,"name":username});
   
fetch(`${onlineurl}/salid/`, {
  method: 'POST', // or 'PUT'
  headers: {
    'Content-Type': 'application/json',
  },
  body: data,
})
.then(response => response.json())
.then(data => {
  console.log('Success:', data);
})
.catch((error) => {
  console.error('Error:', error);
});
};



async function loginUser(event, login, password, history) {
  //pressEnter(e);
  // setError(false);
  // setIsLoading(true);
   event.preventDefault();
  const data = await signinUser2(login, password)
  if (data) {
    setTimeout(() => {
      localStorage.setItem("id_token", "1");
     // const data = await signinUser2(login, password)
    //  dispatch({ type: "LOGIN_SUCCESS" });
      // setError(null);
      // setIsLoading(false);

      // if(data.user.type === 'ck')
      // {
        history.push(`${process.env.PUBLIC_URL}/bdash`);
        window.location.reload();
      // } else {
      //   history.push(`/${data.user.group_id}/pos/storedashboard`);
      // }
      
    }, 2000);

  } 
  else {
  // dispatch({ type: "LOGIN_FAILURE" });
   alert("Enter valid credentials");
  //  setError(true);
    //setIsLoading(false);
  }

  //alertImg();
  

}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push(`${process.env.PUBLIC_URL}/login`);
}

// function CartItems(dispatch, user_id, store_id, history, setIsLoading, setError) {
//   const data = await getCheckout(user_id, store_id)
//   dispatch({ type: "CHECKOUT_SUCCESS", data });
  
  
// }
